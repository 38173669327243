import { render, staticRenderFns } from "./Authorization.vue?vue&type=template&id=ec41ac06&scoped=true"
import script from "./Authorization.vue?vue&type=script&lang=js"
export * from "./Authorization.vue?vue&type=script&lang=js"
import style0 from "./Authorization.vue?vue&type=style&index=0&id=ec41ac06&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec41ac06",
  null
  
)

export default component.exports